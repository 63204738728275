import LoginPage from './components/login-page/LoginPage';
import ResetPasswordPage from './components/reset-password-page/ResetPasswordPage';
import NewPasswordPage from './components/new-password-page/NewPasswordPage';
import { Route, Redirect, Switch } from 'react-router-dom';
import { SSO_LOGIN_TYPE } from 'config/auth';
import SsoLoginPage from './components/sso-login-page/SsoLoginPage';

export default function AuthRoutes() {
  return (
    <Switch>
      <Route path='/' exact render={(props) => <LoginPage {...props} />} />
      <Route
        path='/reset-password'
        render={(props) => <ResetPasswordPage {...props} />}
      />
      <Route
        path='/new-password'
        render={(props) => <NewPasswordPage {...props} />}
      />
      <Route
        path='/login/cookie'
        render={(props) => <SsoLoginPage {...props} ssoType={SSO_LOGIN_TYPE.SAML_COOKIE} />}
      />
      <Route path='*'>
        <Redirect to='/' />
      </Route>
    </Switch>
  );
}
