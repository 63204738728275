import { SSO_COOKIE_NAME } from 'config/auth';
import authService from 'lib/authService';
import ErrorBag from 'lib/components/error-bag/ErrorBag';
import { useEffect, useState } from 'react';
import './SsoLoginPage.scss';

const SsoLoginPage = () => {
  const [error, setError] = useState(null);

  useEffect(() => {
    const login = async () => {
      let cookieData = null;

      try {
        const cookieExtractRegex = new RegExp(`(^|;)\\s*${SSO_COOKIE_NAME}\\s*=\\s*([^;]+)`);
        const nonceJwtCookie = document.cookie?.match(cookieExtractRegex)?.pop() ?? null;

        if (nonceJwtCookie) {
          cookieData = JSON.parse(decodeURIComponent(nonceJwtCookie));
        }
      } catch (e) {
        console.error('Error while parsing login cookie', e);
        setError('There was an error while trying to log you in.');
        return;
      }

      if (!((cookieData?.token?.length || cookieData?.errors?.length) && cookieData?.type?.length)) {
        console.error('Invalid login cookie contents', cookieData);
        setError('There was an error while trying to log you in.');
        return;
      }

      if (cookieData?.errors?.length) {
        setError(cookieData.errors.map(v => v?.message ?? null).filter(v => !!v?.length).join('; '));
        return;
      }

      if (cookieData?.token?.length) {
        try {
          await authService.fetchUserAndLogin({ token: cookieData?.token, });
        } catch (e) {
          console.error('Error while retrieving user profile', e?.response, e);
          setError('There was an error while trying to log you in.');
        }
      }
    }

    setTimeout(login, 2000);
  }, []);


  return (
    <div className='sso-login-page'>
      <div className='login-form-container'>
        <h3>Signing in to Exacture...</h3>
        <ErrorBag errors={error?.length ? [error] : []} />
      </div>
      <div className='patent-container'>
        US Patent Number: 11,587,038; 11,263,586; 10,878,380; 10,127,523
      </div>
    </div>
  );
};

export default SsoLoginPage;
