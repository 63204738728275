import { useRef, useEffect, useState } from 'react';

const getGeneratorsWithDefaults = (config) => {
  return {
    ...config,
    __root__: {
      'csv_row.less': () => 'The line has less columns than expected',
      'csv_row.greater': () => 'The line has more columns than expected',
      ...(config.__root__ ?? {}),
    },
    __default__: {
      'object.unknown': ({ label }) => `${label} is not a valid column`,
      'any.required': ({ label }) => `${label} is required`,
      'string.empty': ({ label }) => `${label} is required`,
      'string.alphanumWithSpaces': ({ label }) =>
        `${label} can only contain alphanumeric and space characters`,
      'string.alphanum': ({ label }) =>
        `${label}  can only contain alphanumeric characters`,
      'string.max': ({ label }) => `${label} exceeded maximum length`,
      'any.only': ({ label }) => `${label} has an invalid value`,
      'number.base': ({ label }) => `${label} must be a number`,
      'number.positive': ({ label }) => `${label} must be positive`,
      'number.unsafe': ({ label }) =>
        `${label} integer value is greater than 2^31-1 and cannot be parsed`,
      'duplicate': ({ label }) => `${label} column is duplicated`,
      ...(config.__default__ ?? {}),
      __default__: ({ label, errorCode }) =>
        `${label} has an unknown error (${errorCode})`,
    },
  };
};

const regex = /\.([0-9]+)\.|^([0-9]+)\.|\.([0-9]+)$/g;
function extractIndices(string) {
  const indices = string.match(regex)?.map((s) => s.replace(/\./g, '')) || [];
  return [string.replace(regex, '.0.').replace(/^\.|\.$/g, ''), indices];
}

function ImportError({ error, errorGenerator, columnLabels = {} }) {
  const field = useRef(null);
  const [message, setMessage] = useState('');

  useEffect(() => {
    // set title (hint) only if text is cut (by CSS)
    if (field?.current) {
      if (message && field.current.scrollWidth > field.current.offsetWidth) {
        field.current.title = message;
      } else {
        field.current.title = '';
      }
    }
  }, [message]);

  useEffect(() => {
    try {
      if ('string' === typeof error) {
        setMessage(error);
      } else if (error instanceof Error) {
        setMessage(error.message);
      } else if (error.details?.message) {
        setMessage(`Line ${error.line} - ${error.details.message}`);
      } else if (typeof error.details === 'string') {
        setMessage(`Line ${error.line} - ${error.details}`);
      } else {
        const fieldsWithError = Object.keys(error.details);
        const messages = fieldsWithError.reduce((messages, field) => {
          const [parsedField, fieldIndices] = extractIndices(field);

          const generatorsWithDefaults = getGeneratorsWithDefaults(
            errorGenerator,
          );
          const generators =
            {
              ...generatorsWithDefaults.__default__,
              ...generatorsWithDefaults[parsedField],
            } || generatorsWithDefaults.__default__;

          const errors = error.details[field];

          errors.forEach((errorCode) => {
            const [parsedCode, codeIndices] = extractIndices(errorCode);

            const generator = generators[parsedCode] || generators.__default__;

            const args = {
              errorCode: parsedCode,
              codeIndices: codeIndices,
              label: columnLabels[parsedField] || field,
              parsedField,
              field,
              fieldIndices,
              error,
              line: error?.line,
            };
            const prefix = errorGenerator.__prefix__?.(args) ?? '';
            const message = generator(args);

            // Skip message if generator doesn't return any message for that code
            if (prefix?.length || message?.length) {
              messages.push(`${prefix}${message}`);
            }
          });
          return messages;
        }, []);

        const lineMsg = `Line ${error.line} - `;
        const idMsg = error.externalId
          ? `Ext. ID ${error.externalId} - `
          : error.id
            ? `ID ${error.id} - `
            : '';

        setMessage(`${lineMsg}${idMsg}${messages.join(', ')}.`);
      }
    } catch (err) {
      setMessage('An unknown error occurred while parsing error response');
    }
  }, [error]);

  return <p ref={field}>{message}</p>;
}

export default ImportError;
