import { useEffect, useReducer, useState, useContext } from 'react';
import { AppContext } from 'App';
import { reducer, initialState } from './usersFormReducer';
import { USERS_FORM_ACTIONS } from './usersFormReducer';
import usersFormValidator from './usersFormValidator';
import usersService from './usersService';
import USER_EVENTS from 'events/user-events';
import authService from 'lib/authService';
import { useDropzone } from 'react-dropzone';
import USER_STATUS from 'config/userStatus';
import { getIsUserTaggable, userToFormState } from './dataTransform';
import tagsService from 'lib/tagsService';
import appBus from 'lib/appBus';
import COUNTRIES from 'config/countries.json';
import useNavigationPrompt from 'lib/useNavigationPrompt';

const useUsersForm = (props) => {
  const [rolesOptions, setRolesOptions] = useState([]);
  const [tagsOptions, setTagsOptions] = useState([]);
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    appState: { currentUser },
  } = useContext(AppContext);

  useNavigationPrompt(state.isDirty);

  const id = props.self ? currentUser.id : props.match?.params?.id;
  const isOwnProfile = Number(currentUser.id) === Number(id);

  const onPictureDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) {
      return dispatch({
        type: USERS_FORM_ACTIONS.USER_ADDS_PHOTO,
        payload: {
          value: state.originalPhoto.value,
          errors: [
            'Maximum allowed size for images is 5 MB and the accepted MIME types are image/jpeg, image/png',
          ],
        },
      });
    }

    dispatch({
      type: USERS_FORM_ACTIONS.USER_ADDS_PHOTO,
      payload: {
        value: acceptedFiles[0],
        errors: [],
      },
    });
  };

  const dropzoneConfig = {
    multiple: false,
    accept: 'image/jpeg, image/png, image/jpg',
    maxSize: 5 * 1024 * 1024,
    onDrop: onPictureDrop,
  };

  const dropzoneHook = useDropzone(dropzoneConfig);

  const userPressesChangePhoto = dropzoneHook.open;
  const getPreview = () =>
    (state.photo.value.path && URL.createObjectURL(state.photo.value)) ||
    state.photo.value?.url;

  const getRoles = () => {
    return authService
      .getRoles()
      .then((roles) =>
        setRolesOptions(
          roles.data.map((role) => ({ value: role.id, label: role.role })),
        ),
      )
      .catch(() => {
        dispatch({
          type: USERS_FORM_ACTIONS.RESET_STATE,
          payload: {
            errors: ['There was an error in fetching the user roles.'],
          },
        });
      });
  };

  const countryOptions = COUNTRIES.map((country) => ({
    label: country,
    value: country,
  }));

  const getTags = () => {
    return tagsService.getTags().then((tags) => {
      setTagsOptions(tags.data.map(({ id, label }) => ({ value: id, label })));
    });
  };

  const userSetsTags = (tags) => {
    return dispatch({
      type: USERS_FORM_ACTIONS.USER_SETS_TAGS,
      payload: tags,
    });
  };

  const getUser = () => {
    return (
      id &&
      usersService
        .getUser(id)
        .then((user) => {
          dispatch({
            type: USERS_FORM_ACTIONS.RESET_STATE,
            payload: userToFormState(user),
          });
        })
        .catch((e) => {
          dispatch({
            type: USERS_FORM_ACTIONS.RESET_STATE,
            payload: e.payload,
          });
        })
    );
  };

  const getStatuses = () => {
    const statuses = Object.values(USER_STATUS).map((value) => ({
      value,
      label: value.toUpperCase(),
    }));

    switch (state.originalStatus.value) {
      case USER_STATUS.DRAFT:
        return statuses;
      case USER_STATUS.DEACTIVATED:
        return statuses;
      case USER_STATUS.ACTIVE:
        return statuses.filter((status) => status.value !== USER_STATUS.DRAFT);
      default:
        return statuses;
    }
  };

  const userSetsStatus = (value) => {
    return dispatch({
      type: USERS_FORM_ACTIONS.USER_CHANGES_STATUS,
      payload: value,
    });
  };

  const userSetsNoPasswordAuth = (value) => {
    return dispatch({
      type: USERS_FORM_ACTIONS.USER_CHANGES_NO_PASSWORD_AUTH,
      payload: value,
    });
  };

  const photoIsDifferent = () => {
    const photo = JSON.stringify(state.photo.value);
    const originalPhoto = JSON.stringify(state.originalPhoto.value);

    return photo !== originalPhoto;
  };

  const saveUser = (data) => {
    return usersService
      .saveUser(data, { isOwnProfile })
      .then((userResponse) => {
        if (state.photo.value?.size && photoIsDifferent()) {
          return usersService
            .saveUserPhoto(userResponse.id, state.photo.value)
            .then((photoResponse) => [userResponse, photoResponse]);
        }
        return [userResponse];
      })
      .then(([userResponse, photoResponse]) => {
        if (photoResponse) {
          userResponse.profilePhoto = photoResponse;
        }

        if (isOwnProfile) {
          appBus.pub(USER_EVENTS.PROFILE_CHANGED, userResponse);
        }

        props.history.push(
          isOwnProfile ? `/my-profile` : `/users/${userResponse.id}`,
        );
      });
  };

  useEffect(() => {
    state.loading &&
      usersFormValidator(state)
        .then(saveUser)
        .catch((e) => {
          dispatch({
            type: USERS_FORM_ACTIONS.RESET_STATE,
            payload: e.payload,
          });
        })
        .finally(() => {
          dispatch({ type: USERS_FORM_ACTIONS.APP_FINISHES_SUBMISSION });
          document.getElementsByClassName('is-invalid')[0]?.scrollIntoView();
        });
  }, [state.loading]);

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (!isOwnProfile) {
      Promise.all([getRoles(), getTags()]);
    }
  }, []);

  const userTypesName = (e) => {
    return dispatch({
      type: USERS_FORM_ACTIONS.USER_TYPES_NAME,
      payload: e.target.value || '',
    });
  };

  const userTypesAddress = (e) => {
    return dispatch({
      type: USERS_FORM_ACTIONS.USER_TYPES_ADDRESS,
      payload: e.target.value || '',
    });
  };

  const userTypesCity = (e) => {
    return dispatch({
      type: USERS_FORM_ACTIONS.USER_TYPES_CITY,
      payload: e.target.value || '',
    });
  };

  const userSetsCountry = (value) => {
    return dispatch({
      type: USERS_FORM_ACTIONS.USER_SETS_COUNTRY,
      payload: value || '',
    });
  };

  const userTypesCompany = (e) => {
    return dispatch({
      type: USERS_FORM_ACTIONS.USER_TYPES_COMPANY,
      payload: e.target.value || '',
    });
  };

  const userTypesPosition = (e) => {
    return dispatch({
      type: USERS_FORM_ACTIONS.USER_TYPES_POSITION,
      payload: e.target.value || '',
    });
  };

  const userSetsRoles = (roles) => {
    return dispatch({
      type: USERS_FORM_ACTIONS.USER_SETS_ROLES,
      payload: roles,
    });
  };

  const userSetsPerformInspections = (value) => {
    return dispatch({
      type: USERS_FORM_ACTIONS.USER_SETS_PERFORM_INSPECTIONS,
      payload: value,
    });
  };

  const userAddsEmailField = () => {
    return dispatch({
      type: USERS_FORM_ACTIONS.USER_ADDS_EMAIL_FIELD,
    });
  };

  const userRemovesEmailField = (index) => {
    return dispatch({
      type: USERS_FORM_ACTIONS.USER_REMOVES_EMAIL_FIELD,
      payload: index,
    });
  };

  const userTypesEmail = (e, index) => {
    return dispatch({
      type: USERS_FORM_ACTIONS.USER_TYPES_EMAIL,
      payload: { index, value: e.target.value || '' },
    });
  };

  const userAddsPhoneField = () => {
    return dispatch({
      type: USERS_FORM_ACTIONS.USER_ADDS_PHONE_FIELD,
    });
  };

  const userRemovesPhoneField = (index) => {
    return dispatch({
      type: USERS_FORM_ACTIONS.USER_REMOVES_PHONE_FIELD,
      payload: index,
    });
  };

  const userTypesPhone = (e, index) => {
    return dispatch({
      type: USERS_FORM_ACTIONS.USER_TYPES_PHONE,
      payload: { index, value: e.target.value || '' },
    });
  };

  const userSubmitsForm = (e) => {
    e.preventDefault();
    return dispatch({ type: USERS_FORM_ACTIONS.USER_SUBMITS_FORM });
  };

  const userRemovesPhoto = () => {
    return dispatch({ type: USERS_FORM_ACTIONS.USER_REMOVES_PHOTO });
  };

  const userPressesCancelChangePhoto = () => {
    return dispatch({
      type: USERS_FORM_ACTIONS.USER_PRESSES_CANCEL_CHANGE_PHOTO,
    });
  };

  const canShowTags = () =>
    getIsUserTaggable(state.roles.value) && !isOwnProfile;

  return {
    state,
    userTypesName,
    userTypesAddress,
    userTypesCity,
    userSetsCountry,
    userTypesCompany,
    userTypesPosition,
    userSetsRoles,
    userSetsPerformInspections,
    userSetsTags,
    countryOptions,
    rolesOptions,
    tagsOptions,
    canShowTags,
    userRemovesEmailField,
    userAddsEmailField,
    userAddsPhoneField,
    userRemovesPhoneField,
    userTypesEmail,
    userTypesPhone,
    userSubmitsForm,
    userPressesChangePhoto,
    getInputProps: dropzoneHook.getInputProps,
    getPreview,
    userRemovesPhoto,
    userPressesCancelChangePhoto,
    getStatuses,
    userSetsStatus,
    userSetsNoPasswordAuth,
    photoIsDifferent,
    isOwnProfile,
  };
};

export default useUsersForm;
