import ROLES from 'config/roles';
import { MAX_FIELD_CHARS } from './usersFormReducer';
import { TAGGABLE_USER_ROLES } from './usersPermissions';

export const queryParamsToGETParams = (params) => {
  if (params.sortBy && params.sortOrder) {
    params.order = { [params.sortBy]: params.sortOrder };
    delete params.sortBy;
    delete params.sortOrder;
  }

  return params;
};

export const getIsUserTaggable = (roleList) => {
  return roleList.some((role) => TAGGABLE_USER_ROLES.includes(role.value));
};

export const userStateToPOSTParams = (state, isOwnProfile) => {
  // if i have the path, user wants to upload new photo
  const profilePhoto = state.photo.value.path
    ? // so I don't attach the profilePhoto field, and we proceed to upload the photo separately
    undefined
    : // if i have an original photo id and I don't have a photo size, it means the user wants to remove the photo
    state.originalPhoto.value.id && !state.photo.value.url
      ? { id: state.originalPhoto.value.id, s3Path: '' }
      : undefined;

  const canPerformWebAppInspections = isOwnProfile
    ? undefined
    : state.roles.value.some((role) => role.value === ROLES.INSPECTOR) &&
    state.canPerformWebAppInspections;

  return {
    name: state.name.value,
    status: isOwnProfile ? undefined : state.status.value,
    noPasswordAuth: state.noPasswordAuth === true,
    city: state.city.value,
    country: state.country?.value?.value ?? '',
    companyName: state.company.value || null,
    companyPosition: state.position.value || null,
    emails: state.emails.map((email) => ({ email: email.value })),
    phoneNumbers: state.phones
      .map((phone) => ({ phone: phone.value }))
      .filter((phone) => phone.phone !== ''),
    roles: isOwnProfile
      ? undefined
      : state.roles.value.map((role) => ({ id: role.value })),
    canPerformWebAppInspections,
    profilePhoto,
    tags:
      !getIsUserTaggable(state.roles?.value || []) || isOwnProfile
        ? undefined
        : state.tags.value.map((tag) => tag.label),
  };
};

export const setValue = (key, data) => {
  const [responseKey, formKey] = Array.isArray(key)
    ? [key[0], key[1]]
    : [key, key];

  return {
    value: data[responseKey] || '',
    errors: [],
    charsLeft: MAX_FIELD_CHARS[formKey] - (data[responseKey] || '').length,
  };
};

export const setArrayValue = (key, fk, data) =>
  data[key]?.length > 0
    ? (data[key] || []).map((value) => ({ value: value[fk], errors: [] }))
    : [{ value: '', errors: [] }];

export const userToFormState = (response) => ({
  name: setValue('name', response),
  company: setValue(['companyName', 'company'], response),
  city: setValue('city', response),
  country: {
    value: response.country
      ? { value: response.country, label: response.country }
      : null,
    errors: [],
  },
  emails: setArrayValue('emails', 'email', response),
  phones: setArrayValue('phoneNumbers', 'phone', response),
  position: setValue(['companyPosition', 'company'], response),
  roles: {
    value: response.roles.map((role) => ({
      value: role.id,
      label: role.role,
    })),
    errors: [],
  },
  tags: {
    value:
      response.tags?.map((tag) => ({
        value: tag.id,
        label: tag.label,
      })) || [],
    errors: [],
  },
  status: { value: response.status, label: response.status },
  noPasswordAuth: response.noPasswordAuth === true,
  originalStatus: { value: response.status, label: response.status },
  id: response.id,
  canPerformWebAppInspections: response.canPerformWebAppInspections,
  photo: {
    value: response.profilePhoto,
    errors: [],
  },
  originalPhoto: {
    value: response.profilePhoto,
    errors: [],
  },
});
