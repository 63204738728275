import makeReducer from 'lib/makeReducer';
import USER_STATUS from 'config/userStatus';

const MAX_CHARS = 50;
const MAX_VARCHAR = 255;

export const MAX_FIELD_CHARS = {
  name: MAX_CHARS,
  position: MAX_CHARS,
  company: 500,
  city: MAX_VARCHAR,
};

const INITIAL_FORM_STATE = {
  name: {
    value: '',
    errors: [],
    charsLeft: MAX_FIELD_CHARS['name'],
  },
  photo: {
    value: {},
    errors: [],
  },
  originalPhoto: {
    value: {},
    errors: [],
  },
  roles: {
    value: [],
    errors: [],
  },
  tags: {
    value: [],
    errors: [],
  },
  city: { value: '', errors: [], charsLeft: MAX_FIELD_CHARS['city'] },
  country: { value: null, errors: [] },
  emails: [{ value: '', errors: [] }],
  phones: [{ value: '', errors: [] }],
  position: {
    value: '',
    errors: [],
    charsLeft: MAX_FIELD_CHARS['position'],
  },
  company: {
    value: '',
    errors: [],
    charsLeft: MAX_FIELD_CHARS['company'],
  },
  canPerformWebAppInspections: false,
  errors: [],
  loading: false,
  status: { value: USER_STATUS.DRAFT, label: USER_STATUS.DRAFT },
  noPasswordAuth: false,
  originalStatus: { value: USER_STATUS.DRAFT, label: USER_STATUS.DRAFT },
  id: null,
  isDirty: false,
};

export const USERS_FORM_ACTIONS = {
  USER_TYPES_NAME: 'USER_TYPES_NAME',
  USER_ADDS_PHOTO: 'USER_ADDS_PHOTO',
  USER_REMOVES_PHOTO: 'USER_REMOVES_PHOTO',
  USER_TYPES_POSITION: 'USER_TYPES_POSITION',
  USER_TYPES_CITY: 'USER_TYPES_CITY',
  USER_SETS_COUNTRY: 'USER_SETS_COUNTRY',
  USER_TYPES_COMPANY: 'USER_TYPES_COMPANY',
  USER_CHANGES_STATUS: 'USER_CHANGES_STATUS',
  USER_CHANGES_NO_PASSWORD_AUTH: 'USER_CHANGES_NO_PASSWORD_AUTH',
  USER_ADDS_EMAIL_FIELD: 'USER_ADDS_EMAIL_FIELD',
  USER_REMOVES_EMAIL_FIELD: 'USER_REMOVES_EMAIL_FIELD',
  USER_TYPES_EMAIL: 'USER_TYPES_EMAIL',
  USER_ADDS_PHONE_FIELD: 'USER_ADDS_PHONE_FIELD',
  USER_REMOVES_PHONE_FIELD: 'USER_REMOVES_PHONE_FIELD',
  USER_TYPES_PHONE: 'USER_TYPES_PHONE',
  RESET_STATE: 'RESET_STATE',
  USER_SETS_ROLES: 'USER_SETS_ROLES',
  USER_SETS_PERFORM_INSPECTIONS: 'USER_SETS_PERFORM_INSPECTIONS',
  USER_SETS_TAGS: 'USER_SETS_TAGS',
  USER_SUBMITS_FORM: 'USER_SUBMITS_FORM',
  USER_PRESSES_CANCEL_CHANGE_PHOTO: 'USER_PRESSES_CANCEL_CHANGE_PHOTO',
  APP_FINISHES_SUBMISSION: 'APP_FINISHES_SUBMISSION',
};

const setValue = (key, state, action) => {
  const max = MAX_FIELD_CHARS[key];
  if (max && action.payload.length > max) {
    return state;
  }

  return {
    ...state,
    [key]: {
      value: action.payload,
      errors: [],
      charsLeft: max && max - action.payload.length,
    },
    isDirty: true,
  };
};

const removeArrayValue = (key, state, action) => {
  const newValues = JSON.parse(JSON.stringify(state[key]));
  newValues.splice(action.payload, 1);

  return {
    ...state,
    [key]: newValues,
    isDirty: true,
  };
};

const removeEmptyArrayValues = (key, state) => {
  const sanitizedValues = state[key].filter((element) => element.value !== '');
  return sanitizedValues.length > 0
    ? sanitizedValues
    : [{ value: '', errors: [] }];
};

const userTypesInArrayValue = (key, state, action) => {
  return {
    ...state,
    [key]: state[key].map((element, index) => {
      if (index === action.payload.index) {
        return { value: action.payload.value, errors: [] };
      }

      return { value: element.value, errors: element.errors };
    }),
    isDirty: true,
  };
};

const USER_FORM_REDUCER_CONFIG = {
  [USERS_FORM_ACTIONS.USER_TYPES_NAME]: (state, action) => {
    return setValue('name', state, action);
  },
  [USERS_FORM_ACTIONS.USER_TYPES_POSITION]: (state, action) => {
    return setValue('position', state, action);
  },
  [USERS_FORM_ACTIONS.USER_TYPES_CITY]: (state, action) => {
    return setValue('city', state, action);
  },
  [USERS_FORM_ACTIONS.USER_CHANGES_STATUS]: (state, action) => {
    return {
      ...state,
      status: action.payload,
      isDirty: true,
    };
  },
  [USERS_FORM_ACTIONS.USER_CHANGES_NO_PASSWORD_AUTH]: (state, action) => {
    return {
      ...state,
      noPasswordAuth: !!action.payload,
      isDirty: true,
    };
  },
  [USERS_FORM_ACTIONS.USER_SETS_COUNTRY]: (state, action) => {
    return setValue('country', state, action);
  },
  [USERS_FORM_ACTIONS.USER_TYPES_COMPANY]: (state, action) => {
    return setValue('company', state, action);
  },
  [USERS_FORM_ACTIONS.USER_ADDS_PHONE_FIELD]: (state) => {
    return {
      ...state,
      phones: [...state.phones, { value: '', errors: [] }],
    };
  },
  [USERS_FORM_ACTIONS.USER_ADDS_PHOTO]: (state, action) => {
    return {
      ...state,
      photo: action.payload,
      isDirty: true,
    };
  },
  [USERS_FORM_ACTIONS.USER_SETS_PERFORM_INSPECTIONS]: (state, action) => {
    return {
      ...state,
      canPerformWebAppInspections: action.payload,
      isDirty: true,
    };
  },
  [USERS_FORM_ACTIONS.USER_REMOVES_PHOTO]: (state) => {
    return {
      ...state,
      photo: {
        value: { ...state.originalPhoto.value, url: '' },
        errors: [],
      },
      isDirty: true,
    };
  },
  [USERS_FORM_ACTIONS.USER_REMOVES_PHONE_FIELD]: (state, action) => {
    return removeArrayValue('phones', state, action);
  },
  [USERS_FORM_ACTIONS.USER_TYPES_PHONE]: (state, action) => {
    return userTypesInArrayValue('phones', state, action);
  },
  [USERS_FORM_ACTIONS.USER_ADDS_EMAIL_FIELD]: (state) => {
    return {
      ...state,
      emails: [...state.emails, { value: '', errors: [] }],
    };
  },
  [USERS_FORM_ACTIONS.USER_REMOVES_EMAIL_FIELD]: (state, action) => {
    return removeArrayValue('emails', state, action);
  },
  [USERS_FORM_ACTIONS.USER_TYPES_EMAIL]: (state, action) => {
    return userTypesInArrayValue('emails', state, action);
  },
  [USERS_FORM_ACTIONS.RESET_STATE]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [USERS_FORM_ACTIONS.USER_SETS_ROLES]: (state, action) => {
    return {
      ...state,
      roles: { errors: [], value: action.payload },
      isDirty: true,
    };
  },
  [USERS_FORM_ACTIONS.USER_SETS_TAGS]: (state, action) => {
    return {
      ...state,
      tags: { errors: [], value: action.payload },
      isDirty: true,
    };
  },
  [USERS_FORM_ACTIONS.USER_SUBMITS_FORM]: (state) => {
    const phones = removeEmptyArrayValues('phones', state);
    const emails = removeEmptyArrayValues('emails', state);

    return { ...state, emails, phones, loading: true, isDirty: false };
  },
  [USERS_FORM_ACTIONS.APP_FINISHES_SUBMISSION]: (state) => {
    return { ...state, loading: false };
  },
  [USERS_FORM_ACTIONS.USER_PRESSES_CANCEL_CHANGE_PHOTO]: (state) => {
    return {
      ...state,
      photo: {
        value: state.originalPhoto.value,
        errors: [],
      },
    };
  },
};

export const { reducer, initialState } = makeReducer(
  USER_FORM_REDUCER_CONFIG,
  INITIAL_FORM_STATE,
);
