import makeLogger from 'lib/makeLogger';
import {
  companyAttributeToPOSTParams,
  formAnswerToPOSTParams,
  formQuestionToPOSTParams,
  questionGroupFormToPOSTParams,
} from './dataTransform';
import { processQuestionsAPIError } from './settingsQuestionsListValidator';
import { processAPIError } from './settingsAnswersFormValidator';
import { processDefectsAPIError } from './settingsDefectsFormValidator';
import { processCompanyAttributesAPIError } from './settingsCompanyAttributesListValidator';
import { processSettingsQuestionGroupAPIError } from './settingsQuestionGroupsFormValidator';
import { processTypesAPIError } from './settingsTypesFormValidator';
import { processAssetStatusesAPIError } from './settingsAssetStatusesFormValidator';

const { default: request } = require('lib/request');

const log = makeLogger('settingsService');

const defaultSettingsQuestionsListParams = {
  page: 1,
  pageSize: 10,
};

function getQuestionTemplates(params) {
  return request.get('/settings/question-templates', {
    params: queryParamsToGETParams({
      ...defaultSettingsQuestionsListParams,
      ...params,
    }),
  });
}

function getQuestionOptions() {
  return request.get('/inspection-plan-options').then((response) => ({
    ...response.questionOptions,
    aqlLevel: response.aqlLevel,
  }));
}

const defaultSettingsAnswersListParams = {
  search: '',
  select: ['name', 'externalId'],
  relations: ['options'],
};

const getSettingsAnswers = (params) => {
  log('Attempting to fetch settings answers with params', params);

  return request
    .get('/settings/answers', {
      params: {
        ...defaultSettingsAnswersListParams,
        ...params,
      },
    })
    .then((response) => {
      log('Settings answers successfully fetched.', response);
      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in fetching the settings answers for the required params',
        params,
        e,
      );
      return Promise.reject(e);
    });
};

const saveAnswer = (answer) => {
  const query = answer.id
    ? `/settings/answers/${answer.id}`
    : '/settings/answers';
  const method = answer.id ? 'put' : 'post';

  return request[method](query, formAnswerToPOSTParams(answer))
    .then((response) => {
      return response;
    })
    .catch((e) => {
      switch (e.response.data.errorCode) {
        case 'entity_body_001':
          return Promise.reject({
            payload: processAPIError(e.response.data.details, answer),
          });
        default:
          return Promise.reject({
            payload: {
              errors: [
                'An error has occured while performing this operation. Please try again',
              ],
            },
          });
      }
    });
};

const defaultSettingsDefectsListParams = {
  page: 1,
  pageSize: 10,
  search: '',
  sortBy: 'id',
  sortOrder: 'ASC',
  select: ['id', 'name', 'externalId'],
};

const getSettingsDefects = (params) => {
  log('Attempting to fetch settings defects with params', params);

  return request
    .get('/settings/defects', {
      params: {
        ...defaultSettingsDefectsListParams,
        ...params,
      },
    })
    .then((response) => {
      log('Settings defects successfully fetched.', response);

      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in fetching the settings defects for the required params',
        params,
        e,
      );
      return Promise.reject(e);
    });
};
const exportSettingsDefects = (params) => {
  log('Attempting to export settings defects with params', params);

  return request
    .get('/settings/defects/export', {
      params: {
        ...params,
      },
    })
    .then((response) => {
      log('Settings defects successfully exported.', response);

      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in exporting the settings defects for the required params',
        params,
        e,
      );
      return Promise.reject(e);
    });
};

const exportSettingsAnswers = (params) => {
  log('Attempting to export settings answers with params', params);

  return request
    .get('/settings/answers/export', {
      params: {
        ...params,
      },
    })
    .then((response) => {
      log('Settings answers successfully exported.', response);

      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in exporting the settings answers for the required params',
        params,
        e,
      );
      return Promise.reject(e);
    });
};

const exportSettingsQuestions = (params) => {
  log('Attempting to export settings questions with params', params);

  return request
    .get('/settings/question-templates/export', {
      params: {
        ...params,
      },
    })
    .then((response) => {
      log('Settings questions successfully exported.', response);

      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in exporting the settings questions for the required params',
        params,
        e,
      );
      return Promise.reject(e);
    });
};

const exportSettingsQuestionDefects = (params) => {
  log('Attempting to export settings question defects with params', params);

  return request
    .get('/settings/question-templates/export-defects', {
      params: {
        ...params,
      },
    })
    .then((response) => {
      log('Settings question defects successfully exported.', response);

      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in exporting the settings question defects for the required params',
        params,
        e,
      );
      return Promise.reject(e);
    });
};

const exportSettingsQuestionGroups = (params) => {
  log('Attempting to export settings question groups with params', params);

  return request
    .get('/settings/question-group-templates/export', {
      params: {
        ...params,
      },
    })
    .then((response) => {
      log('Settings question groups successfully exported.', response);

      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in exporting the settings question groups for the required params',
        params,
        e,
      );
      return Promise.reject(e);
    });
};
const exportSettingsQuestionGroupAttributes = (params) => {
  log('Attempting to export settings question groups with params', params);

  return request
    .get('/settings/question-group-templates/export/attributes', {
      params: {
        ...params,
      },
    })
    .then((response) => {
      log('Settings question groups successfully exported.', response);

      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in exporting the settings question groups for the required params',
        params,
        e,
      );
      return Promise.reject(e);
    });
};

const defaultAssetStatusesListParams = {
  select: ['name', 'meaning'],
};

const getAssetStatuses = (params) => {
  log('Attempting to fetch asset statuses with params', params);

  return request
    .get('/asset-statuses', {
      params: {
        ...defaultAssetStatusesListParams,
        ...params,
      },
    })
    .then((response) => {
      log('Asset statuses successfully fetched.', response);

      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in fetching the settings defects for the required params',
        params,
        e,
      );
      return Promise.reject(e);
    });
};

const defaultSettingsQuestionGroupsListParams = {
  page: 1,
  pageSize: 10,
};
const getQuestionGroupTemplates = (params) => {
  return request.get('/settings/question-group-templates', {
    params: { ...defaultSettingsQuestionGroupsListParams, ...params },
  });
};

const getQuestionGroupTemplateById = (id) => {
  return request.get(`/settings/question-group-templates/${id}`);
};

const getIsQuestionGroupUsedInInspections = (id) => {
  return request.get(`/settings/question-group-templates/${id}/is-used`);
};

const saveDefect = (defect) => {
  const query = defect.id
    ? `/settings/defects/${defect.id}`
    : '/settings/defects';
  const method = defect.id ? 'put' : 'post';

  return request[method](query, {
    name: defect.name.value.filter(({ text }) => text),
  })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      switch (e.response.data.errorCode) {
        case 'entity_body_001':
          return Promise.reject({
            payload: processDefectsAPIError(e.response.data.details, defect),
          });
        default:
          return Promise.reject({
            payload: {
              errors: [
                'An error has occured while performing this operation. Please try again',
              ],
            },
          });
      }
    });
};
const saveAssetStatus = (status) => {
  return request
    .put(`/asset-statuses/${status.id}`, { name: status.name.value })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      switch (e.response.data.errorCode) {
        case 'entity_body_001':
          return Promise.reject({
            payload: processAssetStatusesAPIError(
              e.response.data.details,
              status,
            ),
          });
        default:
          return Promise.reject({
            payload: {
              errors: [
                'An error has occured while performing this operation. Please try again',
              ],
            },
          });
      }
    });
};

const saveQuestion = (question) => {
  const query = question.id
    ? `/settings/question-templates/${question.id}`
    : '/settings/question-templates';
  const method = question.id ? 'put' : 'post';

  return request[method](query, { ...formQuestionToPOSTParams(question) })
    .then((response) => {
      log('Question saved successfully.', response);
      return response;
    })
    .catch((e) => {
      switch (e.response.data.errorCode) {
        case 'entity_body_001':
          return Promise.reject({
            payload: processQuestionsAPIError(
              e.response.data.details,
              question,
            ),
          });
        default:
          return Promise.reject({
            payload: {
              errors: [
                'An error has occured while performing this operation. Please try again',
              ],
            },
          });
      }
    });
};

const saveQuestionDocument = (questionId, name, file) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('name', name);
  return request
    .post(
      `/settings/question-templates/${questionId}/upload-reference-documents`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    )
    .then((response) => {
      log('Document uploaded successfully');
      return response;
    })
    .catch((e) => {
      log('There was an error in uploading the document', e);
      return Promise.reject({
        payload: {
          errors: [`An error occurred while uploading document ${name}`],
        },
      });
    });
};

const saveQuestionGroup = (group) => {
  const query = group.id
    ? `/settings/question-group-templates/${group.id}`
    : '/settings/question-group-templates';
  const method = group.id ? 'put' : 'post';

  return request[method](query, {
    ...questionGroupFormToPOSTParams(group),
  }).catch((e) => {
    switch (e.response.data.errorCode) {
      case 'entity_body_001':
        return Promise.reject({
          payload: processSettingsQuestionGroupAPIError(
            e.response.data.details,
            group,
          ),
        });
      default:
        return Promise.reject({
          payload: {
            errors: [
              'An error has occured while performing this operation. Please try again',
            ],
          },
        });
    }
  });
};

const defaultCompanyAttributesParams = {
  page: 1,
  pageSize: 10,
};

const getCompanyAttributes = (params) => {
  return request.get('/settings/company-attributes', {
    params: {
      ...defaultCompanyAttributesParams,
      ...params,
    },
  });
};
const defaultCustomAttributesParams = {
  page: 1,
  pageSize: 10,
};

const getCustomAttributes = (params) => {
  return request.get('/settings/custom-attributes', {
    params: {
      ...defaultCustomAttributesParams,
      ...params,
    },
  });
};

const saveCompanyAttribute = (attr) => {
  const method = attr.id ? 'put' : 'post';
  return request[method]('/settings/company-attributes', {
    ...companyAttributeToPOSTParams(attr),
  }).catch((e) => {
    if (e.response.data.errorCode === 'entity_body_001') {
      return Promise.reject({
        payload: processCompanyAttributesAPIError(
          e.response.data.details,
          attr,
        ),
      });
    }
    return Promise.reject({
      payload: {
        errors: [`An error occurred while saving the company attribute`],
      },
    });
  });
};

const defaultWorkObjectListParams = {
  page: 1,
  pageSize: 10,
  select: [
    'id',
    'asset',
    'quantity',
    'source',
    'defaultSource',
    'defaultDestination',
    'sourceLocation',
    'destinationLocation',
    'progress',
    'hasMissingInspectionPlan',
    'lastActivity',
  ],
  relations: ['assetImg', 'sourceImg'],
};

const queryParamsToGETParams = (params) => {
  if (params.sortBy && params.sortOrder) {
    params.order = { [params.sortBy]: params.sortOrder };
    delete params.sortBy;
    delete params.sortOrder;
  }

  return params;
};

const getWorkObjects = (params) => {
  log('Attempting to fetch work objects with params', params);

  return request
    .get('/work-objects', {
      params: queryParamsToGETParams({
        ...defaultWorkObjectListParams,
        ...params,
      }),
    })
    .then((response) => {
      log('Work objects successfully fetched.', response);
      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in fetching the work objects for the required params',
        params,
        e,
      );
      return Promise.reject(e);
    });
};

const defaultWorkObjectDetailParams = {
  select: [
    'id',
    'status',
    'quantity',
    'deadline',
    'remainingQuantity',
    'userCanEdit',
  ],
  relations: [
    'asset',
    'customFields',
    'source',
    'asset.images',
    'partialWorkObjects',
    'workObjectSteps',
    'originalWorkflow',
    'activities',
  ],
};

const getWorkObject = ({ id, ...params }) => {
  log('Attempting to fetch work object with params', params);

  return request
    .get(`/work-objects/${id}`, {
      params: queryParamsToGETParams({
        ...defaultWorkObjectDetailParams,
        ...params,
      }),
    })
    .then((response) => {
      log('Work object successfully fetched.', response);
      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in fetching the work object for the required params',
        params,
        e,
      );
      return Promise.reject(e);
    });
};

const defaultSettingsTypesListParams = {
  page: 1,
  pageSize: 10,
  search: '',
  select: ['id', 'name'],
};

const getSettingsAssetTypes = (params) => {
  log('Attempting to fetch settings asset types with params', params);

  return request
    .get('/asset-types', {
      params: queryParamsToGETParams({
        ...defaultSettingsTypesListParams,
        ...params,
      }),
    })
    .then((response) => {
      log('Settings asset types successfully fetched.', response);

      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in fetching the settings asset types for the required params',
        params,
        e,
      );
      return Promise.reject(e);
    });
};

const saveAssetType = (assetType) => {
  const query = assetType.id ? `/asset-types/${assetType.id}` : '/asset-types';
  const method = assetType.id ? 'put' : 'post';

  return request[method](query, { name: assetType.name.value })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      switch (e.response.data.errorCode) {
        case 'entity_body_001':
          return Promise.reject({
            payload: processTypesAPIError(e.response.data.details, assetType),
          });
        default:
          return Promise.reject({
            payload: {
              errors: [
                'An error has occured while performing this operation. Please try again',
              ],
            },
          });
      }
    });
};

const getSettingsSourceTypes = (params) => {
  log('Attempting to fetch settings source types with params', params);

  return request
    .get('/source-types', {
      params: queryParamsToGETParams({
        ...defaultSettingsTypesListParams,
        ...params,
      }),
    })
    .then((response) => {
      log('Settings source types successfully fetched.', response);

      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in fetching the settings source types for the required params',
        params,
        e,
      );
      return Promise.reject(e);
    });
};

const getSettingsInspectionTypes = (params) => {
  log('Attempting to fetch settings inspection types with params', params);

  return request
    .get('/inspection-types', {
      params,
    })
    .then((response) => {
      log('Settings inspection types successfully fetched.', response);
      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in fetching the settings inspection types for the required params',
        params,
        e,
      );
      return Promise.reject(e);
    });
};

const saveSourceType = (sourceType) => {
  const query = sourceType.id
    ? `/source-types/${sourceType.id}`
    : '/source-types';
  const method = sourceType.id ? 'put' : 'post';

  return request[method](query, { name: sourceType.name.value })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      switch (e.response.data.errorCode) {
        case 'entity_body_001':
          return Promise.reject({
            payload: processTypesAPIError(e.response.data.details, sourceType),
          });
        default:
          return Promise.reject({
            payload: {
              errors: [
                'An error has occured while performing this operation. Please try again',
              ],
            },
          });
      }
    });
};

const defaultAssetCustomMeasuresParams = {
  select: ['id', 'label'],
};
const getAssetCustomMeasures = (params) => {
  return request
    .get('/settings/custom-measurements', {
      params: { ...defaultAssetCustomMeasuresParams, ...params },
    })
    .catch((e) => {
      log(e);
      return Promise.reject({
        payload: {
          errors: ['Could not load asset custom measures'],
        },
      });
    });
};

const settingsService = {
  getQuestionGroupTemplates,
  getQuestionGroupTemplateById,
  getQuestionTemplates,
  getQuestionOptions,
  getSettingsAnswers,
  saveAnswer,
  getSettingsDefects,
  exportSettingsDefects,
  exportSettingsAnswers,
  exportSettingsQuestions,
  exportSettingsQuestionDefects,
  exportSettingsQuestionGroups,
  exportSettingsQuestionGroupAttributes,
  getIsQuestionGroupUsedInInspections,
  getAssetStatuses,
  getAssetCustomMeasures,
  getSettingsInspectionTypes,
  saveDefect,
  saveAssetStatus,
  saveQuestionDocument,
  saveQuestion,
  saveQuestionGroup,
  getCompanyAttributes,
  getCustomAttributes,
  saveCompanyAttribute,
  getWorkObjects,
  getWorkObject,
  getSettingsAssetTypes,
  saveAssetType,
  getSettingsSourceTypes,
  saveSourceType,
};

export default settingsService;
