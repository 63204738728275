import classNames from 'classnames';
import { useState, useEffect } from 'react';
import { QUESTION_GROUP_STATUS_OPTIONS } from 'config/questionOptions';

import Accordion from 'lib/components/accordion/Accordion';
import Button from 'lib/components/button/Button';
import Input from 'lib/components/input/Input';
import Radio from 'lib/components/radio/Radio';
import Select from 'lib/components/select/Select';
import settingsService from 'modules/settings/settingsService';

const QUESTION_COLUMNS = [
  {
    title: '',
    className: 'edit-cell',
    textAlign: 'center',
  },
  {
    title: '#',
    className: 'id-cell',
    textAlign: 'left',
  },
  {
    title: 'Question',
    className: 'question-cell',
    textAlign: 'left',
  },
  {
    title: 'Question Type',
    className: 'question-type-cell',
    textAlign: 'left',
  },
  {
    title: 'Sample Size',
    className: 'sample-size-cell',
    textAlign: 'right',
  },
  {
    title: '',
    className: 'toggle-cell',
    textAlign: 'right',
  },
];

const QuestionGroupTitle = ({
  data,
  tagOptions,
  assetTypeOptions,
  sourceTypeOptions,
  inspectionTypeOptions,
  customAttributeOptions,
  companyAttributeOptions,
  onInputChange,
  onGroupedSelectChange,
  onAddQuestion,
  isQgUsed,
}) => {
  return (
    <div className='question-group-form'>
      <div className='form-row question-group-name-container'>
        <Input
          label='Question group name'
          value={data.name.value}
          errors={data.name.errors}
          data-testid='QuestionGroupForm.nameInput'
          onChange={(ev) => onInputChange('name', ev.target.value)}
        />
      </div>
      <div className='form-row question-group-status-container'>
        <Radio
          label='Question group status'
          options={QUESTION_GROUP_STATUS_OPTIONS}
          value={data.status.value}
          errors={data.status.errors}
          data-testid='QuestionGroupForm.statusSelect'
          disabled={data.isAssignedToPlans || isQgUsed}
          onChange={(status) => onInputChange('status', status)}
        />
        <p className='field-description'>
          Status can only be changed to draft as long as the question group is
          not assigned to any plans.
        </p>
      </div>
      <div className='form-row question-group-tags-container'>
        <Select
          isMulti={true}
          isClearable={false}
          isSearchable={true}
          isCreatable
          label='Tags'
          options={tagOptions}
          value={data.tags.value}
          errors={data.tags.errors}
          data-testid='QuestionGroupForm.tagsInput'
          onChange={(value) => onInputChange('tags', value)}
        />
        <p className='field-description'>
          Tags are used to match against source tags and assemble inspection
          plans automatically.
        </p>
      </div>
      <div className='form-row question-group-source-type-container'>
        <Select
          isMulti={true}
          isClearable={false}
          isSearchable={true}
          label='Source type'
          options={sourceTypeOptions}
          placeholder='Any'
          value={data.sourceTypes.value}
          errors={data.sourceTypes.errors}
          data-testid='QuestionGroupForm.sourceTypesInput'
          onChange={(value) => onInputChange('sourceTypes', value)}
        />
        <p className='field-description'>
          Source types are used to match sources and assemble inspection plans
          automatically.
        </p>
      </div>
      <div className='form-row question-group-asset-type-container'>
        <Select
          isMulti={true}
          isClearable={false}
          isSearchable={true}
          label='Asset type'
          options={assetTypeOptions}
          placeholder='Any'
          value={data.assetTypes.value}
          errors={data.assetTypes.errors}
          data-testid='QuestionGroupForm.assetTypesInput'
          onChange={(value) => onInputChange('assetTypes', value)}
        />
        <p className='field-description'>
          Asset types are used to match assets and assemble inspection plans
          automatically.
        </p>
      </div>
      <div className='form-row question-group-inspection-type-container'>
        <Select
          isMulti={true}
          isClearable={false}
          isSearchable={true}
          label='Inspection type'
          placeholder='Any'
          options={inspectionTypeOptions}
          value={data.inspectionTypes.value}
          errors={data.inspectionTypes.errors}
          data-testid='QuestionGroupForm.inspectionTypesInput'
          onChange={(value) => onInputChange('inspectionTypes', value)}
        />
        <p className='field-description'>
          Inspection types are used to match inspection plans by type and
          assemble them automatically.
        </p>
      </div>
      <div className='form-row question-group-custom-attribute-container'>
        <Select
          isMulti={true}
          isGrouped={true}
          isClearable={false}
          isSearchable={true}
          label='Custom attributes'
          placeholder='Any'
          options={customAttributeOptions}
          value={data.customAttributes.value}
          errors={data.customAttributes.errors}
          data-testid='QuestionGroupForm.customAttributesInput'
          onChange={(value, action) =>
            onGroupedSelectChange('customAttributes', value, action)
          }
          isOptionSelected={(option, selectedValues) =>
            selectedValues?.some(
              (item) =>
                item.groupValue === option.groupValue &&
                item.value === option.value,
            )
          }
        />
        <p className='field-description'>
          Custom attributes are used to match inspection plans by type and
          assemble them automatically.
        </p>
      </div>
      <div className='form-row question-group-company-attribute-container'>
        <Select
          isMulti={true}
          isGrouped={true}
          isClearable={false}
          isSearchable={true}
          label='Company attributes'
          placeholder='Any'
          options={companyAttributeOptions}
          value={data.companyAttributes.value}
          errors={data.companyAttributes.errors}
          data-testid='QuestionGroupForm.companyAttributesInput'
          onChange={(value, action) =>
            onGroupedSelectChange('companyAttributes', value, action)
          }
          isOptionSelected={(option, selectedValues) =>
            selectedValues?.some(
              (item) =>
                item.groupValue === option.groupValue &&
                item.value === option.value,
            )
          }
        />
        <p className='field-description'>
          Company attributes are used to match inspection plans by type and
          assemble them automatically.
        </p>
      </div>
      <div className='form-row question-group-order-container'>
        <Input
          label='Order'
          value={data.order.value}
          errors={data.order.errors}
          data-testid='QuestionGroupForm.orderInput'
          onChange={(ev) => onInputChange('order', ev.target.value)}
        />
        <p className='field-description'>
          Determines the order of question group on an inspection plan. A lower
          order number will show up first.
        </p>
      </div>
      <div className='form-row question-group-add-question-container'>
        <Button
          onClick={onAddQuestion}
          className='medium-button'
          data-testid='QuestionGroupForm.addQuestionButton'
        >
          Add question
        </Button>
      </div>
    </div>
  );
};

const SettingsQuestionGroupForm = ({
  data,
  tagOptions,
  assetTypeOptions,
  sourceTypeOptions,
  inspectionTypeOptions,
  customAttributeOptions,
  companyAttributeOptions,
  children,
  onInputChange,
  onGroupedSelectChange,
  onAddQuestion,
  className,
  actions,
  ...props
}) => {
  const [isQgUsed, setIsQgUsed] = useState(false);
  const [isQgUsedLoading, setIsQgUsedLoading] = useState(false);

  useEffect(() => {
    if (data?.id) {
      setIsQgUsed(false);
      setIsQgUsedLoading(true);
      settingsService.getIsQuestionGroupUsedInInspections(data.id).then((res) => {
        setIsQgUsed(res?.isUsed === true);
      }).finally(() => {
        setIsQgUsedLoading(false)
      });
    }
  }, [data?.id]);

  if (!data) {
    return null;
  }
  return (
    <Accordion
      className={classNames('qm-settings-question-group editable', className)}
      title={
        <QuestionGroupTitle
          tagOptions={tagOptions}
          assetTypeOptions={assetTypeOptions}
          sourceTypeOptions={sourceTypeOptions}
          inspectionTypeOptions={inspectionTypeOptions}
          customAttributeOptions={customAttributeOptions}
          companyAttributeOptions={companyAttributeOptions}
          onInputChange={onInputChange}
          onGroupedSelectChange={onGroupedSelectChange}
          onAddQuestion={onAddQuestion}
          data={data}
          isQgUsed={isQgUsed || isQgUsedLoading}
        />
      }
      isOpen={true}
      hideToggle
      {...props}
    >
      <div className='questions-table'>
        {children.length > 0 && (
          <div className={classNames('table-row table-header')}>
            {QUESTION_COLUMNS.map((col, idx) => (
              <div
                key={idx}
                className={classNames('table-cell', col.className, {
                  [`align-${col.textAlign}`]: col.textAlign,
                })}
              >
                {col.title}
              </div>
            ))}
          </div>
        )}
        {children}
        {actions}
      </div>
    </Accordion>
  );
};

export default SettingsQuestionGroupForm;
