import { Link } from 'react-router-dom';
import USER_STATUS from 'config/userStatus';
import useUsersDetail from 'modules/users/useUsersDetail';
import ProtectedComponent from 'lib/components/protected-component/ProtectedComponent';
import Avatar, { AVATAR_SIZES } from 'lib/components/avatar/Avatar';
import StatusPill, {
  STATUS_PILL_VARIANT,
} from 'lib/components/status-pill/StatusPill';
import {
  ADD_USER_ROLES,
  TAGGABLE_USER_ROLES,
} from 'modules/users/usersPermissions';
import { reduceLocation } from 'lib/dataTransform';
import NamedAvatar from 'lib/components/named-avatar/NamedAvatar';
import ROLES from 'config/roles';

import IconButton from 'lib/components/icon-button/IconButton';
import EditButton from 'lib/components/edit-button/EditButton';
import { ReactComponent as LockScreenRotation } from 'assets/images/lock-screen-rotation.svg';
import { ReactComponent as Email } from 'assets/images/email.svg';
import { ReactComponent as PhoneInTalk } from 'assets/images/phone-in-talk.svg';

import './UsersDetailPage.scss';

const UsersDetailPage = (props) => {
  const {
    state,
    userClicksResendPassword,
    userClicksEditUser,
    userClicksCoordinator,
    isEditVisible,
  } = useUsersDetail(props);
  const { user, loading, errors } = state;

  if (loading) {
    return null;
  }

  if (errors.length) {
    return null;
  }
  const hasMultiColumnContacts =
    user.emails?.length > 1 || user.phoneNumbers?.length > 1;

  const showAssignedCoordinators = !!(user?.roles || []).find(
    (role) => role?.id === ROLES.INSPECTOR,
  );
  const showAssignedSources = (user?.roles || []).some((role) =>
    TAGGABLE_USER_ROLES.includes(role?.id),
  );

  const contactsList = hasMultiColumnContacts ? (
    <>
      <div className='group'>
        <div className='title'>Contact:</div>
        {(user?.emails || []).map((itm, idx) => (
          <div className='info' key={`email-${idx}`}>
            <a href={`mailto:${itm.email}`}>
              <Email />
              <span>{itm.email}</span>
            </a>
          </div>
        ))}
      </div>
      <div className='group'>
        <div className='title'>&nbsp;</div>
        {(user?.phoneNumbers || []).map((itm, idx) => (
          <div className='info' key={`phone-${idx}`}>
            <a href={`tel:${itm.phone}`}>
              <PhoneInTalk />
              <span>{itm.phone}</span>
            </a>
          </div>
        ))}
      </div>
    </>
  ) : (
    <div className='group'>
      <div className='title'>Contact:</div>
      {user.emails?.[0]?.email && (
        <div className='info'>
          <a href={`mailto:${user.emails[0].email}`}>
            <Email />
            <span>{user.emails[0].email}</span>
          </a>
        </div>
      )}
      {user.phoneNumbers?.[0]?.phone && (
        <div className='info'>
          <a href={`tel:${user.phoneNumbers[0].phone}`}>
            <PhoneInTalk />
            <span>{user.phoneNumbers[0].phone}</span>
          </a>
        </div>
      )}
    </div>
  );

  return (
    <div className='users-detail-page' data-testid='UsersDetailPage.container'>
      <div className='user-header container-m row'>
        <Avatar
          size={AVATAR_SIZES.XL}
          placeholder={user.name}
          url={user.profilePhoto?.url}
        />
        <div className='details'>
          <div className='name'>{user.name}</div>
          <div className='info'>
            <span>ID: {user.id}</span>
            <span>Status: {user.status}</span>
            <span>Password Auth: {user.noPasswordAuth === true ? 'No' : 'Yes'}</span>
          </div>
          <div className='tags-container'>
            {(user?.roles || []).map((role, idx) => (
              <StatusPill
                key={`role-${idx}`}
                variant={STATUS_PILL_VARIANT.NOTIFY}
              >
                {role.role}
              </StatusPill>
            ))}
          </div>
        </div>
        <div className='buttons-container'>
          {isEditVisible() && (
            <EditButton
              onClick={userClicksEditUser}
              data-testid='UsersDetailPage.edit'
            >
              Edit
            </EditButton>
          )}
          {user.status === USER_STATUS.DRAFT && user.noPasswordAuth !== true && (
            <ProtectedComponent allowed={ADD_USER_ROLES}>
              <span className='resend-password-container'>
                <IconButton
                  disabled={state.passwordEmailButton.disabled}
                  prefix={<LockScreenRotation />}
                  onClick={userClicksResendPassword}
                  data-testid='UsersDetailPage.resendPassword'
                >
                  Resend password
                </IconButton>
                {!!state.passwordEmailButton.message && (
                  <div className='message'>
                    {state.passwordEmailButton.message}
                  </div>
                )}
              </span>
            </ProtectedComponent>
          )}
        </div>
      </div>
      <div className='contact-information container-m row'>
        <div className='group'>
          <div className='title'>Position & Company:</div>
          <div className='info'>{user.companyPosition || '-'}</div>
          <div className='info bold'>{user.companyName || '-'}</div>
        </div>
        {contactsList}
        <div className='group'>
          <div className='title'>Location:</div>
          <div className='info bold'>
            {[user.city, user.country].filter((i) => !!i).join(', ') || '-'}
          </div>
        </div>
      </div>
      <div className='info-card-container container-m row'>
        <div className='info-card'>
          <div className='card-title'>Latest Activity</div>
          <div className='card-body'>
            <div className='empty'>
              No activity yet for this user. As soon as they do something, it
              will show up here.
            </div>
          </div>
        </div>
        <div className='right-container'>
          {showAssignedSources && (
            <div className='info-card'>
              <div className='card-title'>Assigned Sources</div>
              <div className='card-body'>
                {!user?.sourcesTagged?.length ? (
                  <div className='empty'>
                    No assigned sources yet for this user. As soon as some are
                    assigned, they will show up here.
                  </div>
                ) : (
                  (user?.sourcesTagged || []).map((source) => (
                    <div key={source?.id}>
                      <p className='source-name'>
                        <Link to={`/sources/${source?.id}`}>
                          {source?.name}
                        </Link>
                      </p>

                      <p className='source-location'>
                        {reduceLocation(source?.location, 'city', 'country')}
                      </p>
                    </div>
                  ))
                )}
              </div>
            </div>
          )}
          {showAssignedCoordinators && (
            <div className='info-card'>
              <div className='card-title'>Assigned Coordinators</div>
              <div className='card-body'>
                {!user?.coordinatorsTagged?.length ? (
                  <div className='empty'>
                    No assigned coordinators yet for this user. As soon as some
                    are assigned, they will show up here.
                  </div>
                ) : (
                  (user?.coordinatorsTagged || []).map((coordinator) => (
                    <NamedAvatar
                      onClick={() => userClicksCoordinator(coordinator)}
                      user={coordinator}
                      key={coordinator?.id}
                    />
                  ))
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UsersDetailPage;
