import { DEFAULT_LANGUAGE } from 'config/languages';

import { formatQuestionOrder } from 'lib/components/question-detail/dataTransform';

import Button from 'lib/components/button/Button';
import DropdownMenu, {
  MenuItem,
} from 'lib/components/dropdown-menu/DropdownMenu';
import Modal from 'lib/components/modal/Modal';
import Pagination from 'lib/components/pagination/Pagination';
import QuestionDetail from 'lib/components/question-detail/QuestionDetail';
import QuestionsModal from '../settings-questions-modal/SettingsQuestionsModal';
import SearchInput from 'lib/components/search-input/SearchInput';
import Select from 'lib/components/select/Select';
import SettingsQuestionFormActions from '../settings-question-form-actions/SettingsQuestionFormActions';
import SettingsQuestionGroup from '../settings-question-group/SettingsQuestionGroup';
import SettingsQuestionGroupForm from '../settings-question-group/SettingsQuestionGroupForm';

import useSettingsQuestionGroupsList from 'modules/settings/useSettingsQuestionGroupsList';

import './SettingsQuestionGroupsListPage.scss';

const paginationOptions = [
  { value: 10, label: '10 per page' },
  { value: 50, label: '50 per page' },
  { value: 100, label: '100 per page' },
];

const DependencyInputGroup = ({
  isInModal,

  action,
  actionOptions,
  onActionChange,

  question,
  questionOptions,
  onQuestionChange,

  criteria,
  criteriaOptions,
  onCriteriaChange,
}) => {
  return (
    <div className='dependency-container'>
      <div className='form-label'>Dependency</div>
      <div className='flex'>
        <div>
          <Select
            value={action?.value}
            onChange={onActionChange}
            options={actionOptions}
            className='dependency-action'
            isInModal={isInModal}
          />
        </div>
        {action?.value?.value !== false && (
          <>
            <div className='dependency-question-container'>
              <Select
                className='dependency-question'
                value={question?.value}
                errors={question?.errors}
                options={questionOptions}
                onChange={onQuestionChange}
                isInModal={isInModal}
              />
            </div>
            <div>
              <Select
                className='dependency-criteria'
                value={criteria?.value}
                errors={criteria?.errors}
                options={criteriaOptions}
                onChange={onCriteriaChange}
                isInModal={isInModal}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default function SettingsQuestionGroupsListPage(params) {
  const vm = useSettingsQuestionGroupsList(params);

  const getPageSize = () =>
    paginationOptions.find(
      (option) => option.value === Number(vm.state.pageSize),
    );

  const renderQuestion = (question, isInModal) => {
    const questionOptions = vm.getQuestionFormOptions(question);
    const hasErrors = !![
      ...question.dependencyQuestion.errors,
      ...question.dependencyCriteria.errors,
    ].length;

    return (
      <>
        <QuestionDetail
          questionOptions={questionOptions}
          key={question.id}
          data={question}
          id={formatQuestionOrder(question)}
          language={DEFAULT_LANGUAGE}
          hideDependency
          hasErrors={hasErrors}
          onMoveUp={() => vm.userMovesQuestion(question.order, 'up')}
          onMoveTop={() => vm.userMovesQuestion(question.order, 'top')}
          onMoveDown={() => vm.userMovesQuestion(question.order, 'down')}
          onMoveBottom={() => vm.userMovesQuestion(question.order, 'bottom')}
          onDelete={() => vm.userRemovesQuestion(question.questionTemplateId)}
          isMoveUpDisabled={questionOptions.isMoveUpDisabled}
          isMoveDownDisabled={questionOptions.isMoveDownDisabled}
          isDeleteDisabled={questionOptions.isDeleteDisabled}
        >
          <Button
            onClick={() => vm.userClicksEditQuestion(question)}
            className='medium-button outline-button quick-edit-question-button'
          >
            Edit question
          </Button>
        </QuestionDetail>
        {question.order > 0 && (
          <div className='qm-question-detail settings-question-dependency-group'>
            <div className='table-row question-body'>
              <div className='table-cell body-cell'>
                <DependencyInputGroup
                  actionOptions={questionOptions.dependencyAction}
                  action={question.dependencyAction}
                  onActionChange={(value) =>
                    vm.userChangesQuestionInput(
                      question.order,
                      'dependencyAction',
                      value,
                    )
                  }
                  questionOptions={questionOptions.possibleDependencies}
                  question={question.dependencyQuestion}
                  onQuestionChange={(value) =>
                    vm.userChangesQuestionInput(
                      question.order,
                      'dependencyQuestion',
                      value,
                    )
                  }
                  isInModal={isInModal}
                  criteriaOptions={questionOptions.dependencyAnswerOptions}
                  criteria={question.dependencyCriteria}
                  onCriteriaChange={(value) =>
                    vm.userChangesQuestionInput(
                      question.order,
                      'dependencyCriteria',
                      value,
                    )
                  }
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div className='settings-question-groups-list-page'>
      <div className='settings-header'>
        <h4>Question groups</h4>
        <DropdownMenu
          data-testid='SettingsQuestionGroupsListPage.importDropdown'
          items={[
            <MenuItem
              label='Question groups'
              data-testid='SettingsQuestionGroupsListPage.importQuestionGroups'
              onClick={() =>
                params.history.push('/settings/question-groups/import')
              }
            />,
            <MenuItem
              label='Question group attributes'
              data-testid='SettingsQuestionGroupsListPage.importQuestionGroupAttributes'
              onClick={() =>
                params.history.push(
                  '/settings/question-groups/import-attributes',
                )
              }
            />,
          ]}
        >
          <Button
            data-testid='SettingsQuestionGroupsListPage.importDropdownButton'
            className='medium-button'
          >
            Import
          </Button>
        </DropdownMenu>

        <DropdownMenu
          data-testid='SettingsQuestionGroupsListPage.exportDropdown'
          items={[
            <MenuItem
              label='Question groups'
              data-testid='SettingsQuestionGroupsListPage.exportQuestionGroups'
              onClick={vm.userClicksExportGroups}
            />,
            <MenuItem
              label='Question group attributes'
              data-testid='SettingsQuestionGroupsListPage.exportQuestionGroupAttributes'
              onClick={vm.userClicksExportAttributes}
            />,
          ]}
        >
          <Button
            data-testid='SettingsQuestionGroupsListPage.exportDropdownButton'
            className='medium-button'
          >
            Export
          </Button>
        </DropdownMenu>
      </div>
      <p className='form-info'>
        Changing a question group here will affect all the inspection plans
        (which haven't been started) with that group inside.
      </p>
      <div className='actions-container'>
        <SearchInput
          data-testid='SettingsQuestionGroupsListPage.search'
          value={vm.state.search}
          onChange={vm.setSearch}
        />
        <Button
          data-testid='SettingsQuestionGroupsListPage.addQuestionGroup'
          className='medium-button'
          onClick={vm.userClicksAddQuestionGroup}
        >
          Add new question group
        </Button>
      </div>
      <div className='question-groups-container'>
        {vm.state.data.map((group) =>
          group.id === vm.state.form?.id ? (
            <SettingsQuestionGroupForm
              data={vm.state.form}
              key={vm.state.form.id}
              tagOptions={vm.state.tagOptions}
              inspectionTypeOptions={vm.state.inspectionTypeOptions}
              customAttributeOptions={vm.state.customAttributeOptions}
              companyAttributeOptions={vm.state.companyAttributeOptions}
              assetTypeOptions={vm.state.assetTypeOptions}
              sourceTypeOptions={vm.state.sourceTypeOptions}
              onInputChange={vm.userChangesQuestionGroupFormInput}
              onGroupedSelectChange={vm.userSetsGroupedSelect}
              onAddQuestion={vm.userOpensAddQuestionModal}
              actions={
                <SettingsQuestionFormActions
                  title='question group'
                  onSave={vm.userClicksSaveQuestionGroupForm}
                  onCancel={vm.userClicksCancelQuestionGroupForm}
                  disabled={vm.state.processing}
                />
              }
            >
              {vm.state.form.questions
                .sort((a, b) => a.order - b.order)
                .map(renderQuestion)}
            </SettingsQuestionGroupForm>
          ) : (
            <SettingsQuestionGroup
              vm={vm}
              data={group}
              key={group.id}
              initialOpen={false}
              editable={!vm.state.form}
              onEditClick={() => vm.userClicksEditQuestionGroup(group)}
            />
          ),
        )}
      </div>
      <div className='table-controls'>
        <div className='page-size'>
          <label>Show:</label>
          <Select
            className='page-size-select'
            options={paginationOptions}
            value={getPageSize()}
            onChange={(option) => vm.setPageSize(option.value)}
            isSearchable={false}
            menuPlacement='auto'
          />
        </div>
        <div className='pagination'>
          <Pagination
            pageSize={vm.state.pageSize}
            page={vm.state.page}
            count={vm.state.count}
            threshold={7}
            setPage={vm.setPage}
          />
        </div>
      </div>
      <Modal
        isOpen={vm.state.isAddModalOpen}
        title='Add question group'
        className='settings-question-groups-list-page--add-modal'
        onRequestClose={vm.userClicksCancelQuestionGroupForm}
        data-testid='SettingsQuestionGroupsList.addModal'
        actions={
          <SettingsQuestionFormActions
            title='question group'
            onSave={vm.userClicksSaveQuestionGroupForm}
            onCancel={vm.userClicksCancelQuestionGroupForm}
            disabled={vm.state.processing}
          />
        }
      >
        <SettingsQuestionGroupForm
          data={vm.state.form}
          tagOptions={vm.state.tagOptions}
          inspectionTypeOptions={vm.state.inspectionTypeOptions}
          customAttributeOptions={vm.state.customAttributeOptions}
          companyAttributeOptions={vm.state.companyAttributeOptions}
          assetTypeOptions={vm.state.assetTypeOptions}
          sourceTypeOptions={vm.state.sourceTypeOptions}
          onInputChange={vm.userChangesQuestionGroupFormInput}
          onGroupedSelectChange={vm.userSetsGroupedSelect}
          onAddQuestion={vm.userOpensAddQuestionModal}
          isInModal
        >
          {vm.state.form?.questions
            .sort((a, b) => a.order - b.order)
            .map((q) => renderQuestion(q, true))}
        </SettingsQuestionGroupForm>
      </Modal>
      <QuestionsModal
        isOpen={vm.state.addQuestionOptions.isModalOpen}
        onRequestClose={vm.userCancelsAddQuestionModal}
        data={vm.getAddQuestionList()}
        selectedCount={vm.state.addQuestionOptions.selected.length}
        onSubmit={vm.userAssignsQuestions}
        sortBy={vm.state.addQuestionOptions.sortBy}
        sortOrder={vm.state.addQuestionOptions.sortOrder}
        page={vm.state.addQuestionOptions.page}
        pageSize={vm.state.addQuestionOptions.pageSize}
        count={vm.state.addQuestionOptions.count}
        setPage={vm.userSetsAddQuestionModalPage}
        onSearchChange={vm.userSearchesAddQuestionModal}
        setSortBy={vm.userSortsAddQuestionModal}
        onRowClick={vm.userTogglesQuestion}
      />
    </div>
  );
}
